import { render, staticRenderFns } from "./ProjectCardBusiness.vue?vue&type=template&id=469f5996&scoped=true&"
import script from "./ProjectCardBusiness.vue?vue&type=script&lang=js&"
export * from "./ProjectCardBusiness.vue?vue&type=script&lang=js&"
import style0 from "./ProjectCardBusiness.vue?vue&type=style&index=0&id=469f5996&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "469f5996",
  null
  
)

export default component.exports